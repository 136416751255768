body {
  background-color: '#F5F8F9';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Typography */
@font-face {
  font-family: 'ApexNew';
  src: url(./Fonts/ApexNew/ApexNew-Book.woff) format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'ApexNew';
  src: url(./Fonts/ApexNew/ApexNew-BookItalic.woff) format('woff');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'ApexNew';
  src: url(./Fonts/ApexNew/ApexNew-Medium.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'ApexNew';
  src: url(./Fonts/ApexNew/ApexNew-MediumItalic.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Inconsolata';
  src: url(./Fonts/Inconsolata/Inconsolata-Regular.woff) format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Inconsolata';
  src: url(./Fonts/Inconsolata/Inconsolata-Bold.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

html,
body {
  font-family: 'Lato Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: '#404040';
}
h1 {
  font-family: 'ApexNew';
  font-weight: 400;
  font-size: 27px;
  line-height: 31px;
}
h2 {
  font-family: 'ApexNew';
  font-weight: 400;
  font-size: 23px;
  line-height: 26px;
}
h3 {
  font-family: 'ApexNew';
  font-size: 17px;
  line-height: 21px;
}
h4 {
  font-family: 'ApexNew';
  font-size: 16px;
  line-height: 20px;
}
label {
  font-size: 14px;
  line-height: 19px;
}
.textDetails {
  font-size: 13px;
  line-height: 15px;
}
.textMicro {
  font-size: 8px;
  line-height: 11px;
}
.textType {
  font-size: 10px;
}
.textButton {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 12px;
}
.numberCharts {
  font-family: 'Inconsolata';
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.numberChartsDetails {
  font-family: 'Inconsolata';
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
}

.subtitle {
  font-size: 16px;
  line-height: 20px;
  color: #969696;
}

/* Biomarker colors */
.color-primary {
  color: #00778b;
}
.color-primary-light {
  color: #147b8f;
}
.color-temperature {
  color: #ff9500;
}

.color-pulse-rate {
  color: #d0021b;
}

.color-respiration {
  color: #007aff;
}

.color-oxygen {
  color: #5ac8fa;
}

.colorLightGrey {
  color: #969696;
}
